import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 5×2\\@90% 1RM`}</p>
    <p>{`DB Stiff Leg Deadlifts 5×4`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`20 Jumping Lunges/Leg`}</p>
    <p>{`20 V Ups`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`There is still time to sign up for the CrossFit Open!
  **`}</strong>{`Register now at: Games.CrossFit.com.   Scores for WOD 19.1 need to
be submitted by 8:00pm each Monday.***`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We’ll be showing the “Don’t Give Up” video that we shot at The
Ville after class today at 1:30.  The video is about :18 and will be
shown next door at Spectrum Sight & Sounds theater room so stick around
and take a look if you didn’t get to see it Saturday.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`You can now schedule a massage with Jennifer May, our newest member
and massage therapist! Jennifer is offering a special for the month of
February. You can get a full hour massage for \\$50!! The massage room is
located on the left as soon as you walk into the gym. She works by
appointment only, so email her to schedule.
 `}<a parentName="em" {...{
            "href": "mailto:j.zenmassagetherapy@gmail.com"
          }}>{`j.zenmassagetherapy@gmail.com`}</a>{`.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      